import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';

export class Sabel extends Component {
  static displayName = Sabel.name;

  render() {
    return (
      <div>
        <ProductOverview Id={22} />
        <ProductOverview Id={23} />
      </div>
    );
  }
}
