import React, { Component } from 'react';

export class Salgsbetingelser extends Component {
  static displayName = Salgsbetingelser.name;

  render() {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>Salgsbetingelser</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Call me or text if you don´t speak Danish!</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Mobile: +45 20 33 39 44 </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Tidspunkt:</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Allstar-Danmark
                har et velassorteret lager i Hellerup Fægte-Klub.</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Der er
                mulighed for at handle mandage-torsdage efter aftale.</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Forretningssteder:</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Adressen til selve butikken er:</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Hellerup Fægte-Klub</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Strandparksvej
                46, 1 (op af metaltrappen på bagsiden af den store hvide bygning)</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>2900 Hellerup (mellem Strandparksvej og Frederikkevej, følg stien)</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Tel: 39 62 31 11</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Mens postadressen er</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Allstar-Danmaek</td>
              <td></td>
            </tr>
            <tr>
              <td>c/o Visiodan A/S</td>
              <td></td>
            </tr>
            <tr>
              <td>Strandvejen 171, 1. sal</td>
              <td></td>
            </tr>
            <tr>
              <td>2900 Hellerup</td>
              <td></td>
            </tr>
            <tr>
              <td>Tlf. 39 62 39 44</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Bestilling og forsendelse:</td>
              <td></td>
            </tr>
            <tr>
              <td>Det meste
                fægteudstyr er på lager, men i visse tilfælde vil det være nødvendigt at
                bestille udstyret hjem fra Tyskland.</td>
            </tr>
            <tr>
              <td>Når varer
                skal bestilles hjem fra Tyskland, skal der påregnes leveringstid.</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Fægteudstyret
                sendes over hele landet. Forsendelsesomkostningerne betales af kunden.</td>
              <td></td>
            </tr>
            <tr>
              <td>Bestillinger kan mailes til
                hans@visiodan.dk.</td>
              <td></td>
            </tr>
            <tr>
              <td>Til hjælp
                ved bestilling kan tabellerne over størrelser benyttes.</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Priser:</td>
              <td></td>
            </tr>
            <tr>
              <td>Når fægtetøj
                skal specialsyes skal priserne tillægges 25%, og udstyret kan ikke byttes.</td>
              <td></td>
            </tr>
            <tr>
              <td>Alle priser
                er opgivet i danske kroner inkl. 25% moms. Ab lager Hellerup.</td>
              <td></td>
            </tr>
            <tr>
              <td>Der forbeholdes ret til prisændringer.</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Betaling:</td>
              <td></td>
            </tr>
            <tr>
              <td>Betaling: Helst med MobilePay til 57953 Allstar-Danmark eller med kort, dog kun i klubben.</td>
              <td></td>
            </tr>
            <tr>
              <td>Ved betaling
                pr. netbank skal fakturanummer oplyses og beløbet skal indbetales inden 8
                dage.</td>
              <td></td>
            </tr>
            <tr>
              <td>Ved for sen
                betaling vil rykkere blive pålagt et rykkergebyr på kr. 50 pr. rykker.</td>
              <td></td>
            </tr>
            <tr>
              <td>Hvis en
                faktura fortsat ikke betales, sendes den til inkasso.</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Ved
                forsendelse skal der ske forudbetaling, hvis intet andet er aftalt.</td>
              <td></td>
            </tr>
            <tr>
              <td>Det samme er
                tilfældet, hvis man er førstegangskunde hos Allstar-Danmark.</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>CVR.nr.: 17 66 02 84</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Allstar-Danmarks
                konto hos Jyske Bank: 5011 1766023</td>
              <td>Ved overførsel fra Danmark</td>
            </tr>
            <tr>
              <td>IBAN nr. til
                kontoen i Jyske Bank: DK8450110001766023</td>
              <td>Ved overførsel fra udlandet</td>
            </tr>
            <tr>
              <td>Swiftkode JYBADKKK</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Venlig hilsen</td>
              <td></td>
            </tr>

            <tr>
              <td>Allstar-Danmark</td>
              <td></td>
            </tr>

            <tr>
              <td>Hans H. von der Osten</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Baggrund: Hans er selv fægter og har været formand for Hellerup Fægte-Klub i 25 år (1991-2016). Har selv fægtet siden 1973 og rejser med landsholdet til stævner. </td>
              <td></td>
            </tr>

          </tbody>
        </table>
      </div>
    );
  }
}
