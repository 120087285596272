import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import { Grid, Image } from 'semantic-ui-react';

export class TilbehoerSabel extends Component {
  static displayName = TilbehoerSabel.name;

  render() {
    return (
      <div>
        <ProductOverview Id={31} />
        <ProductOverview Id={32} />
        <Grid>
          <Grid.Row centered>
              <Image src='./sabel-tilbehoer/parts.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
