import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import { Grid, Image } from 'semantic-ui-react';

export class Faegtepisteogtilbehoer extends Component {
  static displayName = Faegtepisteogtilbehoer.name;

  render() {
    return (
      <div>
        <ProductOverview Id={37} />
        <ProductOverview Id={38} />
        <Grid>
          <Grid.Row centered>
            <Image src='./faegtepiste/opruller.png' alt="GrTab_Anzug" />
            <Image src='./faegtepiste/oprullerdele.png' alt="GrTab_Anzug" />
          </Grid.Row>
          <Grid.Row centered>
            <Image src='./faegtepiste/piste.png' alt="GrTab_Anzug" />
            <Image src='./faegtepiste/spaende.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
