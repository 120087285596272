import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import { Grid, Image } from 'semantic-ui-react';

export class Faegtemesterudstyr extends Component {
  static displayName = Faegtemesterudstyr.name;

  render() {
    return (
      <div>
        <ProductOverview Id={36} />
        <Grid>
          <Grid.Row centered>
            <Image src='./faegtemesterudstyr/aerme.png' alt="GrTab_Anzug" />
            <Image src='./faegtemesterudstyr/aerme2.png' alt="GrTab_Anzug" />
            <Image src='./faegtemesterudstyr/aermer.png' alt="GrTab_Anzug" />
          </Grid.Row>
          <Grid.Row centered>
            <Image src='./faegtemesterudstyr/jakke.png' alt="GrTab_Anzug" />
            <Image src='./faegtemesterudstyr/jakkekortaerme.png' alt="GrTab_Anzug" />
            <Image src='./faegtemesterudstyr/jakkeogfor.png' alt="GrTab_Anzug" />
          </Grid.Row>
          <Grid.Row centered>
            <Image src='./faegtemesterudstyr/model.png' alt="GrTab_Anzug" />
            <Image src='./faegtemesterudstyr/pude.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
