import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Faegtebeklaedning } from './components/beklaedning/Faegtebeklaedning';
import { Sikkerhedstoej } from './components/beklaedning/Sikkerhedstoej';
import { Faegtesko } from './components/beklaedning/Faegtesko';
import { Elveste } from './components/beklaedning/Elveste';
import { Handsker } from './components/beklaedning/Handsker';
import { Masker } from './components/beklaedning/Masker';
import { Fleuret } from './components/vaaben/Fleuret';
import { Kaarde } from './components/vaaben/Kaarde';
import { Sabel } from './components/vaaben/Sabel';
import { TilbehoerFleuret } from './components/vaabentilbehoer/Fleuret';
import { TilbehoerKaarde } from './components/vaabentilbehoer/Kaarde';
import { TilbehoerSabel } from './components/vaabentilbehoer/Sabel';
import { Diverseogfaegtetasker } from './components/diverse/DiverseOgFaegtetasker';
import { Faegtemesterudstyr } from './components/diverse/Faegtemesterudstyr';
import { Faegtepisteogtilbehoer } from './components/diverse/FaegtepisteOgTilbehoer';
import { Hvadboerennybegynderhave } from './components/diverse/HvadBoerEnNybegynderHave';
import { Meldeapparater } from './components/diverse/Meldeapparater';
import { Salgsbetingelser } from './components/diverse/Salgsbetingelser';

class RootApp extends Component {
    static displayName = RootApp.name;

    render() {
        return (
            <Layout {...this.props} >
                <Switch>
                    <Route path='/' component={Home} exact />
                    <Route path='/Faegtebeklaedning' component={Faegtebeklaedning} exact />
                    <Route path='/Sikkerhedstoej' component={Sikkerhedstoej} exact />
                    <Route path='/Elveste' component={Elveste} exact />
                    <Route path='/Handsker' component={Handsker} exact />
                    <Route path='/Faegtesko' component={Faegtesko} exact />
                    <Route path='/Masker' component={Masker} exact />
                    <Route path='/Fleuret' component={Fleuret} exact />
                    <Route path='/Kaarde' component={Kaarde} exact />
                    <Route path='/Sabel' component={Sabel} exact />
                    <Route path='/Tilbehoer-Fleuret' component={TilbehoerFleuret} exact />
                    <Route path='/Tilbehoer-Kaarde' component={TilbehoerKaarde} exact />
                    <Route path='/Tilbehoer-Sabel' component={TilbehoerSabel} exact />
                    <Route path='/Diverse-Diverseogfaegtetasker' component={Diverseogfaegtetasker} exact />
                    <Route path='/Diverse-Faegtemesterudstyr' component={Faegtemesterudstyr} exact />
                    <Route path='/Diverse-Faegtepisteogtilbehoer' component={Faegtepisteogtilbehoer} exact />
                    <Route path='/Diverse-Hvadboerennybegynderhave' component={Hvadboerennybegynderhave} exact />
                    <Route path='/Diverse-Meldeapparater' component={Meldeapparater} exact />
                    <Route path='/Diverse-Salgsbetingelser' component={Salgsbetingelser} exact />
                </Switch>
            </Layout>
        );
    }
}

export const App = RootApp;