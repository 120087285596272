import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';
import { Grid, Image } from 'semantic-ui-react';

export class Diverseogfaegtetasker extends Component {
  static displayName = Diverseogfaegtetasker.name;

  render() {
    return (
      <div>
        <ProductOverview Id={33} />
        <ProductOverview Id={34} />
        <ProductOverview Id={35} />
        <ProductOverview Id={40} />
        <ProductOverview Id={41} />
        <h5>
          Derududover er der mulighed for at få taskerne i nationalfarver og med navn, klubnavn eller landenavn påtrykt. Spørg i butikken.
        </h5>
        <Grid>
          <Grid.Row centered>
              <Image src='./diverse/ws4.png' alt="GrTab_Anzug" />
              <Image src='./diverse/wt-e.png' alt="GrTab_Anzug" />
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
